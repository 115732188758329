import React from 'react';
import { Box, Heading, Text, Image, Flex } from 'rebass/styled-components';
import Img from 'gatsby-image';
import { gridPadding } from '../theme';
import { useHomePageQuery } from '../queries/useHomePageQuery';
import IconShield from '../assets/images/adv-icon-shield.svg';
import IconClock from '../assets/images/adv-icon-clock.svg';
import IconForward from '../assets/images/adv-icon-forward.svg';
import IconGlobal from '../assets/images/adv-icon-global.svg';
import IconDoc from '../assets/images/adv-icon-doc.svg';
import IconThumb from '../assets/images/home/device-icon-thumb.svg';
import { MoreButton } from './MoreButton';
import { Slide } from 'react-awesome-reveal';

type IntroProp = {
  icon: string;
  title: string;
  content: string;
};

const contents: IntroProp[] = [
  {
    icon: IconThumb,
    title: '無需動刀即達成效果',
    content:
      '利用電流對肌膚進行微侵入式緊膚除皺的療程，傷口像針孔般微小，無需動刀就能輔助改善肌膚鬆弛效果。',
  },
  {
    icon: IconDoc,
    title: '客製化治療',
    content:
      '2種深度探頭可針對不同需求選擇，有效改善皺褶、紋路、鬆軟與橘皮問題。',
  },
  {
    icon: IconForward,
    title: '恢復期短',
    content:
      '術後可正常作息，無明顯傷口、不留疤。',
  },
  {
    icon: IconShield,
    title: '美國FDA、台灣TFDA與歐盟CE認證',
    content: '通過全球最高安全標準美國FDA、台灣TFDA及歐盟CE認證，在安全性及有效性上均有保障，全球超過55個國家採用。',
  },
  {
    icon: IconGlobal,
    title: '全球專利技術',
    content: '可精準控制作用溫度、時間與深度的科技，提供最佳治療效果。',
  },
  {
    icon: IconClock,
    title: '效果維持長達兩年',
    content: '術後可維持至兩年。',
  },
];

export const HomePageDevice = () => {
  const {
    media: { device },
  } = useHomePageQuery();

  return (
    <>
      <Heading as="h1" variant="heading.gradient">魔方67優點</Heading>
      <Box variant="wideContainer" sx={{ position: [null, 'relative'] }}>
        <Box maxWidth={[245, null, 448]} mx="auto">
          <Img fluid={device} />
        </Box>
        <Box mt={[48, null, 0]}>

          <Box
            variant="deviceIntro"
            sx={{
              top: [null, 60, 120],
              left: [null, gridPadding],
            }}
          >
            <Slide triggerOnce direction="up" fraction={0.5}>
              {contents.slice(0, 3).map((props) => (
                <Intro  key={props.title} {...props} />
              ))}
            </Slide>
          </Box>

          <Box
            variant="deviceIntro"
            sx={{
              right: [null, gridPadding],
              top: [null, 132, 330],
            }}
          >
            <Slide triggerOnce direction="up">
              {contents.slice(3, contents.length).map((props) => (
                <Intro key={props.title} {...props} />
              ))}
            </Slide>
          </Box>
        </Box>
      </Box>
      <MoreButton href="/about" mt={[48, 100]} mb={60}>
        了解更多
      </MoreButton>
    </>
  );
};

const Intro = ({ icon, title, content }: IntroProp) => (
  <Flex key={title} mb={[40, null, 64]} width={314}>
    <Image src={icon} size={48} sx={{ flexShrink: 0 }} />
    <Box pl={[8, 16]} pt={12}>
      <Heading as="h3" mb={8} fontFamily="serif" fontSize={18}>
        {title}
      </Heading>
      <Text as="h6" fontSize={[14, null, 16]}>
        {content}
      </Text>
    </Box>
  </Flex>
);
