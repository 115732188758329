import React from 'react';
import { Heading, Text, Box, Image, Flex } from 'rebass/styled-components';
import { Tiles } from '@rebass/layout';
import { MoreButton } from './MoreButton';
import IconShield from '../assets/images/adv-icon-shield.svg';
import IconClock from '../assets/images/adv-icon-clock.svg';
import IconForward from '../assets/images/adv-icon-forward.svg';
import IconGlobal from '../assets/images/adv-icon-global.svg';
import IconDoc from '../assets/images/adv-icon-doc.svg';
import IconSmile from '../assets/images/adv-icon-smile.svg';

type Adv = {
  title: string;
  content?: string;
  icon: string;
};

const Contents: Adv[] = [
  {
    icon: IconShield,
    title: '通過歐盟CE、美國FDA雙認證',
    content: '能有效做到拉提、V臉、去除皺紋、減少橘皮組織的圈新科技。',
  },
  {
    icon: IconClock,
    title: '效果維持長達兩年',
    content: '全球55個以上國家採用，術後可維持至兩年。',
  },
  {
    icon: IconForward,
    title: '恢復期短',
    content: '術後可正常作息，無明顯傷口、不留疤。',
  },
  {
    icon: IconGlobal,
    title: '全球專利技術',
    content:
      '全球專利技術，能完全控制真皮層溫度與作用時間的科技，術後不會有傳統拉皮手術常見的殭屍臉副作用，也不會傷害神經等周圍組織。',
  },
  {
    icon: IconDoc,
    title: '客製化治療',
    content: '絕對的溫度控制，針對所需部位加強治療達到精準效果。',
  },
  { icon: IconSmile, title: '適合各類肌膚' },
];

export const HomePageAdv = () => (
  <>
    <Heading variant="heading.gradient">魔方Profound優點</Heading>
    <Box variant="wideContainer">
      <Tiles columns={[1, 2]}>
        {Contents.map(({ icon, title, content }) => (
          <Flex key={icon} mb={[40, 120]}>
            <Image
              src={icon}
              size={[48, 77]}
              mr={['8px', '16px']}
              sx={{
                flexShrink: 0,
              }}
            />
            <Box>
              <Heading
                as="h3"
                fontFamily="serif"
                fontSize={[18, 24]}
                mb="8px"
                pt={[13, 23]}
              >
                {title}
              </Heading>
              {content && (
                <Text as="p" fontSize={[14, 16]}>
                  {content}
                </Text>
              )}
            </Box>
          </Flex>
        ))}
      </Tiles>
    </Box>
    <MoreButton mb={[60, 200]} href="/contact">
      預約詻詢
    </MoreButton>
  </>
);
