import React from 'react';
import { LiteYoutubeEmbed } from 'react-lite-yt-embed';
import { GridItem, GridContainer } from '../components';
import { Video } from '../types';

type Props = {
  videos: Video[];
};

export const VideoGrid = ({ videos }: Props) => (
  <GridContainer>
    {videos.map(({ id, ...others }) => (
      <GridItem {...others}>
        <LiteYoutubeEmbed id={id} />
      </GridItem>
    ))}
  </GridContainer>
);
