import styled from 'styled-components/macro';

export const Clickable = styled.button`
  appearance: none;
  padding: 0;
  border: 0;
  outline: 0;
  box-shadow: none;
  background: none transparent;
  cursor: pointer;
`;
