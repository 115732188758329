import React from 'react';
import { Text } from 'rebass/styled-components';
import { Label } from '@rebass/forms/styled-components';

type Props = {
  htmlFor?: string;
  required?: boolean;
  children: string;
};

export const FormLabel = ({ htmlFor, required, children }: Props) => (
  <Label htmlFor={htmlFor}>
    {required && (
      <Text as="small" color="red">
        *
      </Text>
    )}
    {children}
  </Label>
);
