import React, { ReactNode } from 'react';
import { Heading, Text, Box } from 'rebass/styled-components';

type Props = {
  subTitle?: string;
  children: string;
};

export const PageHeading = ({ subTitle, children }: Props) => (
  <Container>
    {subTitle && (
      <Text as="h6" fontWeight={500} fontSize={['10px', null, '16px']}>
        {subTitle}
      </Text>
    )}
    <Heading mt={['4px', null, '8px']} fontSize={['21px', null, '36px']}>
      {children}
    </Heading>
  </Container>
);

const Container = (props: { children: ReactNode }) => (
  <Box
    {...props}
    pb="12px"
    mt={[null, null, '135px']}
    mb={['80px', null, '72px']}
    sx={{
      position: 'relative',
      fontFamily: 'serif',
      '&::before': {
        display: 'block',
        position: 'absolute',
        content: "''",
        bottom: [0, null, 'auto'],
        left: [0, null, '-142px'],
        top: [null, null, '-24px'],
        width: ['175px', null, '110px'],
        height: ['1px', null, '2px'],
        bg: 'dark',
      },
    }}
  />
);
