import React from 'react';
import { Box, Text, Heading } from 'rebass/styled-components';
import Img from 'gatsby-image';
import { FluidImage } from '../types';

type Props = {
  align: 'left' | 'right';
  title: string;
  children: string;
  slogan: string;
  image: FluidImage;
};

type PX = {
  pl?: (null | number)[];
  pr?: (null | number)[];
};

const px = (side: 'left' | 'right'): PX => {
  const key = side === 'left' ? 'pl' : 'pr';
  return {
    [key]: [null, 100],
  };
};

export const TechSection = ({
  align = 'left',
  title,
  slogan,
  image,
  children,
}: Props) => (
  <Box
    mb={[44, 74]}
    pt={[65, 46]}
    {...px(align)}
    sx={{
      position: 'relative',
    }}
  >
    <Box
      pt={[77, 24]}
      pb={[40, 32]}
      pr={[24, align === 'right' ? 172 : null]}
      pl={[24, align === 'left' ? 172 : null]}
      sx={{
        textAlign: [null, align],
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'dark',
        position: 'relative',
      }}
    >
      <Heading fontFamily="serif" fontSize={[18, 24]}>
        {title}
      </Heading>
      <Text mt="8px" mb="16px" fontSize={[14, 16]}>
        {children}
      </Text>
      <Heading fontFamily="serif" fontSize={[24, 32]} color="white">
        {slogan}
      </Heading>
      <Box
        height="calc(100% + 4px)"
        width="calc(100% + 4px)"
        backgroundColor="secondary"
        sx={{
          position: 'absolute',
          top: '5px',
          left: ['2px', align === 'right' ? '-8px' : '4px'],
          zIndex: -1,
        }}
      />
    </Box>
    <Box
      size={[130, 212]}
      sx={{
        position: 'absolute',
        left: ['50%', align === 'left' ? 0 : 'auto'],
        right: [null, align === 'right' ? 0 : null],
        top: 0,
        transform: ['translateX(-50%)', 'none'],
      }}
    >
      <Img fluid={image.childImageSharp.fluid} />
    </Box>
  </Box>
);
