import React, { PropsWithChildren, ReactNode } from 'react';
import { Box } from 'rebass/styled-components';
import Img from 'gatsby-image';
import { BoxProps } from 'rebass';
import { ExternalLink, GridItem, GridContainer } from '../components';
import { News } from '../types';

type WidthProps = (null | string)[];

const rwd = (
  responsive: boolean,
): {
  Container: typeof BaseContainer | typeof GridContainer;
  thumbnailProps?: { width: WidthProps; maxWidth: WidthProps };
} => {
  if (responsive) {
    return {
      Container: BaseContainer,
      thumbnailProps: { width: [null, '50%'], maxWidth: [null, '433px'] },
    };
  }
  return { Container: GridContainer };
};

type Props = {
  responsive?: boolean;
  newses: News[];
};

export const NewsGrid = ({ responsive = false, newses }: Props) => {
  const { Container, thumbnailProps } = rwd(responsive);

  return (
    <Container>
      {newses.map(({ id, url, thumbnail, ...others }) => (
        <Wrapper href={url} key={id}>
          <GridItem responsive={responsive} {...others}>
            <Box  sx={{ flexShrink: 0 }} {...thumbnailProps}>
              <Img className="grid-item-thumbnail" {...thumbnail} />
            </Box>
          </GridItem>
        </Wrapper>
      ))}
    </Container>
  );
};
const Wrapper = (props: { children: ReactNode; href: string }) => (
  <ExternalLink
    {...props}
    color="inherit"
    sx={{
      textDecoration: 'none',
      '&:hover .grid-item-title': {
        textDecoration: 'underline',
      },
      '&:hover .grid-item-thumbnail': {
        opacity: 0.2,
      },
    }}
  />
);

const BaseContainer = (props: PropsWithChildren<BoxProps>) => (
  <Box mb="80px" {...props} />
);
