import React, { ReactNode } from 'react';
import { Box, Flex, Heading, Text } from 'rebass/styled-components';

type Props = {
  responsive?: boolean;
  children: ReactNode;
  title: string;
  description: string;
  date: string;
};

export const GridItem = ({
  children,
  title,
  description,
  date,
  responsive = false,
}: Props) => (
  <Flex flexDirection={['column', responsive ? 'row' : null]} mb="40px">
    {children}
    <Box pl={[null, responsive ? '32px' : null]}>
      <Heading
        className="grid-item-title h3b"
        fontFamily="serif"
        fontSize={['18px', null, '24px']}
        mt={['12px', responsive ? 0 : null]}
        mb="12px"
        // TODO: This might not be the right solution.
        sx={{
          '@media screen and (max-width: 414px)': {
            fontSize: "21px !important ",
            lineHeight: "30px !important",
            letterSpacing: "1.75px !important",
            fontWeight: "900 !important",
            fontFamily: "'Noto Serif TC' !important",
          }
        }}
      >
        {title}
      </Heading>
      <Text fontSize={['12px', null, '16px']} color="dark" my="12px">
        {description}
      </Text>
      <Text as="time" fontSize={['12px', null, '14px']} color="gray">
        {date}
      </Text>
    </Box>
  </Flex>
);
