import React from 'react';
import { Box, Text } from 'rebass/styled-components';

type Props = {
  id?: string;
  align: 'left' | 'center' | 'right';
  children: string;
};

export const SectionHeading = ({ align = 'left', id, children }: Props) => (
  <Box
    id={id}
    mt={[48, 64]}
    mb={[40, 72]}
    sx={{
      position: 'relative',
      textAlign: [null, align],
    }}
  >
    <Box
      display={['none', 'block']}
      width="100%"
      height="1px"
      backgroundColor="background.gray"
      sx={{
        content: '',
        position: 'absolute',
        top: '50%',
        left: 0,
      }}
    />
    <Text
      display="inline-block"
      pl={[0, ['center', 'right'].includes(align) ? 26 : 0]}
      pr={[0, ['left', 'center'].includes(align) ? 26 : 0]}
      backgroundColor="background.white"
      fontFamily="serif"
      fontWeight="bold"
      fontSize={[24, 39]}
      sx={{
        position: 'relative',
      }}
    >
      {children}
    </Text>
  </Box>
);
