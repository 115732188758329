import React from 'react';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import { Box, Image, Button, Heading, Text } from 'rebass/styled-components';
import { useHomePageQuery } from '../queries/useHomePageQuery';
import Slogan from '../assets/images/profound-67.svg';

type Props = {
  headerTrigger: (node?: Element | null) => void;
};

export const HomePageHero = ({ headerTrigger }: Props) => {
  const { media } = useHomePageQuery();
  return (
    <Box>
      <Box backgroundColor="#210825" sx={{ position: 'relative' }}>
        <Box mx="auto" maxWidth="1440px">
          <Box mx="auto" width="100%" maxWidth="calc(100vh/1024*1440)">
            <Img fluid={media.hero} />
          </Box>
          <Box
            bg="transparent"
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: [169, null, 'calc(100%/1440*359)'],
              backgroundImage: [
                'linear-gradient(to bottom, rgba(255,255,249,0), #ffffff)',
                null,
                'linear-gradient(to top, #fffff9 16%, rgba(255, 255, 250, 0.88) 32%, rgba(255, 255, 250, 0.73) 50%, rgba(255, 255, 252, 0.56) 70%, rgba(255, 255, 255, 0))',
              ],
            }}
          />
          <Box
            width="100%"
            sx={{
              position: 'absolute',
              bottom: [150, null, 'calc(100%/1440*390)'],
              left: 0,
            }}
          >
            <Box variant="wideContainer" color="white">
              <Heading
                mb={['8px', '24px']}
                fontFamily="serif"
                fontSize={[30, 56]}
                ref={headerTrigger}
              >
                <Text className="text-display" sx={{ display: 'inline-block' }}>魔方67電波除皺系統</Text>
              </Heading>
              {/* <Text as="h4" mb="24px" fontSize={[null, 18]}> */}
                {/* 肌膚緊緻，由內而外鎖緊你的美。 */}
              {/* </Text> */}
              <Link to="/contact" style={{display: 'none'}}>
                <Button variant="heroOutline" as="span" 
                  sx={{ 
                    fontSize: 18,
                    fontWeight: 'bold',
                    letterSpacing: 1.89,
                    // backgroundColor: '#FFFFF9',
                  }}
                >
                  預約諮詢
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
