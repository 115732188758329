import React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components/macro';

type Props = {
  children: string;
  type: 'checkbox' | 'radio';
};

export const FormChoice = ({
  children,
  ...props
}: Props & React.InputHTMLAttributes<HTMLInputElement>) => (
  <Box
    display="inline-block"
    mr="8px"
    mt="8px"
    sx={{
      position: 'relative',
      border: '1px solid #000',
    }}
  >
    <Input {...props} />
    <Background>{children}</Background>
  </Box>
);

const Input = styled.input`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;
const Background = styled.div`
  padding: 8px;
  ${Input}:hover + &,
  ${Input}:checked + & {
    background-color: ${({ theme }) => theme.colors.background.light};
  }
`;
