import { useStaticQuery, graphql } from 'gatsby';
import { FeaturedImage, News } from '../types';

export type QueryResponse = {
  allWpNews: {
    edges: {
      node: {
        id: string;
        title: string;
        date: string;
        acf: {
          newsText: string;
          newsUrl: string;
          newsClassification: string;
        };
        featuredImage: {
          node: FeaturedImage;
        };
      };
    }[];
  };
};

export const useNewsesQuery = (latest?: number, classify?:String): News[] => {
  const { allWpNews } = useStaticQuery<QueryResponse>(graphql`
    {
      allWpNews(sort: { fields: dateGmt, order: DESC }) {
        edges {
          node {
            id
            title
            date(formatString: "YYYY.MM.DD")
            acf {
              newsText
              newsUrl
              newsClassification
            }
            ...NewsImage
          }
        }
      }
    }
  `);
  const newses = allWpNews.edges.filter(function(node) {
    if (classify != "0" && node.node.acf.newsClassification == classify) {
      return true
    }
    else if (classify == "0") {
      return true
    }
    
    return false
  }).map(({ node }) => {
    const { id, title, date, acf, featuredImage } = node;
    const thumbnail = {
      title: featuredImage.node.title,
      alt: featuredImage.node.altText,
      fluid: featuredImage.node.localFile.childImageSharp.fluid,
    };

    return {
      id,
      title,
      date,
      thumbnail,
      description: acf.newsText,
      url: acf.newsUrl,
    };
  });

  if (latest) return newses.slice(0, latest);

  return newses;
};
