import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { FluidImageList, FluidImage, ArtDirectionImage } from '../types';

type QueryResponse = {
  heroImage: FluidImage;
  heroImageMobile: FluidImage;
  slides: FluidImageList;
  cases: FluidImageList;
  theoryBg: FluidImage;
  theoryBgMobile: FluidImage;
  deviceImage: FluidImage;
  deviceImageMobile: FluidImage;
  faceBanner: FluidImage;
  faceBannerMobile: FluidImage;
};

type HomePage = {
  media: {
    hero: ArtDirectionImage;
    theory: ArtDirectionImage;
    device: ArtDirectionImage;
    face: ArtDirectionImage;
  };
  slides: FluidObject[];
  cases: {
    title: string;
    image: FluidObject;
  }[];
};

export const useHomePageQuery = (): HomePage => {
  const {
    theoryBg,
    theoryBgMobile,
    heroImage,
    heroImageMobile,
    deviceImage,
    deviceImageMobile,
    faceBanner,
    faceBannerMobile,
    slides,
    cases,
  } = useStaticQuery<QueryResponse>(graphql`
    query HomePageQuery {
      heroImage: file(relativePath: { eq: "home/hero-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroImageMobile: file(
        relativePath: { eq: "home/hero-image-mobile.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      theoryBg: file(relativePath: { eq: "home/theory-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      theoryBgMobile: file(relativePath: { eq: "home/theory-bg-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 767, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      deviceImage: file(relativePath: { eq: "home/device.png" }) {
        childImageSharp {
          fluid(maxWidth: 448, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      deviceImageMobile: file(relativePath: { eq: "home/device-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 245, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      faceBanner: file(relativePath: { eq: "home/face-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 433, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      faceBannerMobile: file(
        relativePath: { eq: "home/face-banner-mobile.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      slides: allFile(
        filter: { relativePath: { glob: "home/slide-image-*.jpg" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 588, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      cases: allFile(
        filter: { relativePath: { glob: "home/case-*.png" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 180, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  return {
    media: {
      hero: [
        heroImageMobile.childImageSharp.fluid,
        {
          ...heroImage.childImageSharp.fluid,
          media: `(min-width: 415px)`,
        },
      ],
      theory: [
        theoryBgMobile.childImageSharp.fluid,
        {
          ...theoryBg.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ],
      device: [
        deviceImageMobile.childImageSharp.fluid,
        {
          ...deviceImage.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ],
      face: [
        faceBannerMobile.childImageSharp.fluid,
        {
          ...faceBanner.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ],
    },
    slides: slides.edges.map(({ node }) => node.childImageSharp.fluid),
    cases: ['皺摺', '皺紋', '鬆弛', '橘皮'].map((title, i) => ({
      title,
      image: cases.edges[i].node.childImageSharp.fluid,
    })),
  };
};
