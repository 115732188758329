import React from 'react';
import { Box, Heading, Text } from 'rebass/styled-components';
import Img from 'gatsby-image';
import { MoreButton } from './MoreButton';
import { useHomePageQuery } from '../queries/useHomePageQuery';

type Theories = {
  title: string;
  content: string;
}[];

const theories: Theories = [
  {
    title: '穩固膠原蛋白',
    content: '負責支撐皮膚內組纖結構，流失即產生肌膚鬆垮及皴紋。',
  },
  {
    title: '補充彈力蛋白',
    content: '穩固膠原蛋白的結構排列，具有絕佳彈性，主導肌膚的張力和彈力',
  },
  {
    title: '增加玻尿酸',
    content: '像海棉一樣可幫助肌膚吸收水分，使肌膚潤澤增加體積厚度。',
  },
];

var window_width = 1440;
if (typeof window !== 'undefined') {
  window_width = window.innerWidth
}

export const HomePageTheory = () => {
  const {
    media: { theory: bg },
  } = useHomePageQuery();

  return (
    <>
      <Heading as="h1" variant="heading.gradient" mb={['10px', '24px']}>
        魔方67原理
      </Heading>
      <Box variant="wideContainer">
        <Text as="p" fontSize={[14, 16]} mb={[40, 32]}>
          利用電流對肌膚進行微侵入式緊膚除皺的療程，傷口像針孔般微小，無需動刀就能輔助改善肌膚鬆弛效果，而全球多國國家都進行這項療程，運用精準溫度與深度感測，改善老化鬆弛的肌膚狀態，達到除皺、拉提的效果。
        </Text>
      </Box>
      <Box bg={[null, '#491741']}>
        <Box
          maxWidth="containerWide"
          mx="auto"
          overflow={[null, 'hidden']}
          sx={{ position: [null, 'relative'] }}
        >
          <Img fluid={bg} />
          <Box
            variant="container"
            pt={[32, 0]}
            color={[null, 'white']}
            sx={{
              position: [null, 'absolute'],
              left: [null, null, '50%'],
              top: [null, '50%'],
              right: [null, 0, 'auto'],
              transform: [null, 'translateY(-50%)'],
              width: [null, '60%', 'auto'],
            }}
          >
            {theories.map(({ title, content }, i) => {
              const margin = i === 1 ? [24, null, 50] : null;
              return (
                <Box key={title} my={margin}>
                  <Heading
                    // as="h3"
                    className={(window_width <= 414) ? "h2" : "h3b"}
                    fontFamily="serif"
                    fontSize={[21, null, 24]}
                    mb="8px"
                  >
                    {title}
                  </Heading>
                  <Text 
                    as="p" 
                    fontSize={[14, 16]}  
                    fontWeight="normal"
                    fontFamily="NotoSansTC-Regular"
                    letterSpacing="1.5px"
                  >
                    {content}
                  </Text>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <MoreButton mt={100} mb={[60, 151]}  href="/about#cases">
        實際案例
      </MoreButton>
    </>
  );
};
