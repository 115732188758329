import React, { PropsWithChildren, useState } from 'react';
import { BoxProps, FlexProps } from 'rebass';
import {
  Heading,
  Box,
  Text,
  Flex,
  Image,
  Button,
} from 'rebass/styled-components';
import styled from 'styled-components/macro';
import { Slide } from 'react-awesome-reveal';
import Img from 'gatsby-image';
import { gridPadding } from '../theme';
import Icon1 from '../assets/images/home/profound-123-icon-1.svg';
import Icon2 from '../assets/images/home/profound-123-icon-2.svg';
import Icon3 from '../assets/images/home/profound-123-icon-3.svg';
import QuoteLeft from '../assets/images/quote-left.svg';
import ChevronLeft from '../assets/icons/chevron-left.svg';
import ChevronRight from '../assets/icons/chevron-right.svg';
import { useHomePageQuery } from '../queries/useHomePageQuery';

const slideCaptions = [
  '魔方在乎您全身的緊緻與美，特殊專利技術同時促進三大新生：膠原蛋白、彈力蛋白、玻尿酸，由內而外鎖緊你的美，無痕緊緻不再專屬於臉，全身肌膚緊緻，鬆弛、皺紋、橘皮，交給Profound魔方。',
  '臨床實證高達 2 倍膠原蛋白、 5倍彈力蛋白、與更多玻尿酸增生。',
  '魔方67運用專利智能溫控針，將RF電波以注射式能量加熱於真皮至皮下組織層，有效改善因老化帶來的皺紋、鬆弛與橘皮組織。',
];

const activeStyle = (isActive: boolean) => ({
  opacity: [null, isActive ? 1 : 0],
  visibility: [null, isActive ? 'visible' : 'hidden'],
  transitionDelay: [null, isActive ? '0s' : null],
});

type Props = {
  mobileHeaderTrigger?: (node?: Element | null) => void;
};

export const HomePage123 = ({ mobileHeaderTrigger }: Props) => {
  const { slides } = useHomePageQuery();
  const [stateCarousel, setStateCarousel] = useState(0);
  const { length: carouselSize } = slides;

  const onForward = () => {
    if (stateCarousel < carouselSize - 1) {
      setStateCarousel(stateCarousel + 1);
      return;
    }

    setStateCarousel(0);
  };

  const onBackward = () => {
    if (stateCarousel === 0) {
      setStateCarousel(carouselSize - 1);
      return;
    }

    setStateCarousel(stateCarousel - 1);
  };

  return (
    <>
      <Heading as="h1" ref={mobileHeaderTrigger} variant="heading.gradient">
        魔方123
      </Heading>
      <Box variant="wideContainer" mb={[72, null, 140]}>
        <StepWrapper>
          <Horizon width={[null, 0, '12.5%']} />
          <Step>
            <StepIcon src={Icon1} />
            <StepCount>1</StepCount>
            <StepContent>
              <StepHighlight>次解決</StepHighlight>
              <StepText>智能溫控針 一次解決肌膚老化問題</StepText>
            </StepContent>
          </Step>
        </StepWrapper>
        <StepWrapper>
          <Horizon width={[null, '15%', '25%']} />
          <Step>
            <StepIcon src={Icon2} />
            <StepCount>2</StepCount>
            <StepContent>
              <StepHighlight>種探頭</StepHighlight>
              <StepText>全身肌膚，訂製專屬療程</StepText>
            </StepContent>
          </Step>
        </StepWrapper>
        <StepWrapper>
          <Horizon width={[null, '30%', '50%']} />
          <Step>
            <StepIcon src={Icon3} />
            <StepCount>3</StepCount>
            <StepContent>
              <StepHighlight>大新生</StepHighlight>
              <StepText>膠原蛋白、彈力蛋白、玻尿酸</StepText>
            </StepContent>
          </Step>
        </StepWrapper>
      </Box>
      <Box
        variant="wideContainer"
        mb={[58, null, 183]}
        sx={{ position: [null, 'relative'] }}
      >
        <Box
          sx={{
            position: [null, 'relative'],
            height: [null, 'calc(50vw - 56px)', '588px'],
          }}
        >
          {slides.map((image, i) => {
            const isEven = i % 2 === 0;
            const active = activeStyle(stateCarousel === i);

            return (
              <Flex
                key={image.src}
                mb={[64, 0]}
                flexDirection={['column', 'row']}
                justifyContent={[null, 'space-between']}
                sx={{
                  position: [null, 'absolute'],
                  left: 0,
                  top: 0,
                  width: '100%',
                }}
              >
                <Box
                  width={[null, 'calc(50% - 16px)']}
                  maxWidth={[null, '588px']}
                  mb={[32, 0]}
                  sx={{
                    [`p${isEven ? 'r' : 'l'}`]: [60, 0],
                    flexShrink: 0,
                  }}
                >
                  <Box width="100%" variant="fadeChange" sx={active}>
                    <Img fluid={image} />
                  </Box>
                </Box>
                <Box
                  width={[null, 'calc(50% - 16px)']}
                  sx={{
                    [`p${isEven ? 'l' : 'r'}`]: [60, 0],
                  }}
                >
                  <Flex
                    height="100%"
                    pt={[18, 0]}
                    pl={[null, 30, 50]}
                    alignItems="center"
                    variant="fadeChange"
                    sx={{
                      backgroundImage: `url(${QuoteLeft})`,
                      backgroundSize: ['80px 63px', null, '177px 141px'],
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: [null, '0 20%'],
                      ...active,
                    }}
                  >
                    <Text
                      as="h3"
                      textAlign="center"
                    >
                      {slideCaptions[i]}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            );
          })}
        </Box>
        <Box
          sx={{
            display: ['none', 'inline-flex'],
            position: 'absolute',
            right: [null, gridPadding, null, 0],
            bottom: [null, '1em', '20%'],
          }}
        >
          <Button onClick={onBackward} variant="carouselController">
            <ChevronLeft width="9px" />
          </Button>
          <Button onClick={onForward} variant="carouselController" ml="16px">
            <ChevronRight width="9px" />
          </Button>
        </Box>
      </Box>
    </>
  );
};

const StepWrapper = (props: PropsWithChildren<FlexProps>) => (
  <Slide triggerOnce direction="left">
    <Flex {...props} alignItems="center" mb={48} />
  </Slide>
);
const Step = (props: PropsWithChildren<FlexProps>) => (
  <Flex
    {...props}
    height={[88, 122]}
    pl={40}
    alignItems="center"
    sx={{
      position: 'relative',
    }}
  />
);
const StepIcon = styled(Image).attrs({
  size: [80, 122],
  sx: {
    position: 'absolute',
    top: 0,
    left: [0, 'calc(100% - 40px)'],
    zIndex: -1,
  },
})``;
const StepContent = (props: PropsWithChildren<BoxProps>) => <Box {...props} />;
const StepHighlight = styled(Heading).attrs({
  fontSize: [24, 36],
  fontFamily: 'serif',
  color: 'dark',
})`
  text-shadow: 2px 2px 0 ${({ theme }) => theme.colors.background.light};
`;
const StepCount = styled(StepHighlight).attrs({
  fontSize: '100px !important',
  mr: [null, '15px'],
})``;
const StepText = styled(Text).attrs({
  mt: '4px',
  fontSize: [12, 24],
})``;
const Horizon = styled(Box).attrs({
  display: ['none', 'block'],
  height: '1px',
  bg: 'dark',
})``;
