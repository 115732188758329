import 'react-slidedown/lib/slidedown.css';
import React, { useState } from 'react';
import { SlideDown } from 'react-slidedown';
import { Text, Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components/macro';
import Plus from '../assets/icons/icon_open.svg';
import Minus from '../assets/icons/icon_close.svg';
import { Clickable } from './Clickable';
import { Qa } from '../types';

export const QaAccordion = ({ title, contents }: Qa) => {
  const [stateIsOpen, setStateIsOpen] = useState(false);
  const onToggleClick = (): void => setStateIsOpen(!stateIsOpen);
  return (
    <Box bg="rgb(246, 233, 242)">
      <TitleContainer onClick={onToggleClick}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p={['24px 16px', '24px 32px 24px 40px']}
        >
          <Text
            className="h3b"
            fontFamily="serif"
            fontSize={['16px', '22px']}
            fontWeight={stateIsOpen ? 'bold' : 'normal'}
            textAlign="left"
          >
            {title}
          </Text>
          <Flex ml="auto" width={['18px', '26px']}>
            <Box
              display={stateIsOpen ? 'none' : 'flex'}
              sx={{ alignItems: 'center' }}
            >
              <Plus />
            </Box>
            <Box
              display={stateIsOpen ? 'flex' : 'none'}
              sx={{ alignItems: 'center' }}
            >
              <Minus />
            </Box>
          </Flex>
        </Flex>
      </TitleContainer>
      <Body>
        {stateIsOpen ? (
          <Box pb={['24px', '48px']}>
            {contents.map( ({ variant, content }, index) => (
              <Text
                as={ (variant === "subTitle") ? "h4" : "h6"}
                key={`${variant}-${index}`}
                // variant={`accordion.${variant}`}
                px={['24px', '80px']}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            ))}
          </Box>
        ) : null}
      </Body>
    </Box>
  );
};

const Body = styled(SlideDown)`
  overflow: hidden;
`;
const TitleContainer = styled(Clickable)`
  width: 100%;
`;
